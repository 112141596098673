import React, { useContext, useEffect, useMemo, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { useDispatch, useSelector } from "react-redux"
import { AttendanceContext } from '../../../Context/AttendanceContext'
import { getInAttendance } from '../../../Services/Attendance-Service'
import { mobileSidebarAction } from "../../../Store/Actions/mobileSidebarAction"
import { selectedChatAction } from "../../../Store/Actions/selectedChatAction"
import { sidebarAction } from '../../../Store/Actions/sidebarAction'
import { ChatListView } from '../../Partials/Chat/ChatListView'
import { mainQueueAction } from '../../../Store/Actions/mainQueueAction'
import { mobileMainQueueAction } from '../../../Store/Actions/mobileMainQueueAction'

function Index() {
  const {
    socketContext,
    initialQueues,
    isLoadingQueues,
    activeChatCustomer,
    setActiveChatCustomer,
    socketInstance,
    setChatReadOnly,
    updateInAttendance
  } = useContext(AttendanceContext)
  const [inAttendances, setInAttendances] = useState([])
  const [openSidebar, setOpenSidebar] = useState(true)
  const [filteredResults, setFilteredResults] = useState(inAttendances);
  const [unreadMessages, setUnreadMessages] = useState([])
  const [filterValue, setFilterValue] = useState('')

  const handleInAttendances = async () => {
    const attendance = await getInAttendance()
    setInAttendances(attendance)
  }

  const dispatch = useDispatch();
  const { mainQueueSidebar, mobileMainQueueSidebar } = useSelector(state => state);

  const chatSelectHandle = (chat) => {
    socketInstance.emit('chatbot_send_seen', {
      cellPhone: chat.phone,
      channelId: chat.channel.id,
    })
    chat.unreadMessages = 0
    setChatReadOnly({ readOnly: false })
    setActiveChatCustomer(chat)
    dispatch(selectedChatAction(chat));
    dispatch(mobileSidebarAction(false));
    const isMobile = document.body.clientWidth < 768
    if(isMobile){
      dispatch(mainQueueAction(false));
      dispatch(mobileMainQueueAction(false))
    }
  };

  const closeAction = (e) => {
    e.preventDefault();
    setOpenSidebar(false)
    dispatch(mainQueueAction(false));
    dispatch(mobileMainQueueAction(false));
    dispatch(sidebarAction(false));
    dispatch(mobileSidebarAction(false));
  };

  const sortInAttendancesByMessages = (updatedMessage) => {
    const index = inAttendances.findIndex(msg => msg.phone === updatedMessage.cellPhone && msg.channel.id === updatedMessage.channel.id)

    if (index !== -1) {
      let updatedObject = inAttendances.splice(index, 1)[0];
      updatedObject.lastMessage = updatedMessage
      inAttendances.unshift(updatedObject)
    }
  }

  const getWSEvent = async () => {
    if (!socketContext) return

    if (socketContext.unreadCount || socketContext.unreadCount === 0) {
      const unreadCount = socketContext

      if (socketContext.unreadCount === 0) {
        setUnreadMessages((prevMessages) =>
          prevMessages.map((msg) => {
            if (msg.from === unreadCount.from && msg.channelId === unreadCount.channelId) {
              return { ...msg, unreadCount: 0 }
            }
            return msg
          })
        )
      }

      setUnreadMessages((prevMessages) => {
        const counterExists = prevMessages.find(msg => msg.from === unreadCount.from && msg.channelId === unreadCount.channelId)
        if (counterExists) {
          const idx = prevMessages.indexOf(counterExists)
          prevMessages.splice(idx, 1)
        }

        return [
          ...prevMessages,
          unreadCount
        ]
      })
    }

    if (socketContext.type === 'inAttendance' && socketContext.eventName === 'chatbot_queue') {
      await handleInAttendances()
    }

    if (socketContext.eventName === 'chatbot_message' && socketContext.attendanceType === 'inAttendance') {
      sortInAttendancesByMessages(socketContext)
    }
  }


  const handleFilter = (e) => {
    const value = e.target.value.toLowerCase();
    setFilterValue(value)
    const originalArrayCopy = [...inAttendances]

    const filteredArray = originalArrayCopy.filter(item => {
      return (
        item.name?.toLowerCase().includes(value) ||
        item.phone?.includes(value) ||
        (Array.isArray(item.externalIds) && item.externalIds?.some(id => id ? id.toString().includes(value) : null))
      )
    });

    setFilteredResults(filteredArray)
  }

  useMemo(() => {
    getWSEvent()
    dispatch(sidebarAction('Chats'))
  }, [socketContext])

  useMemo(async () => {
    if (updateInAttendance && socketContext.type !== 'inAttendance') {
      await handleInAttendances()
    }
  }, [updateInAttendance])

  useMemo(() => {
    if (!isLoadingQueues) {
      setInAttendances(initialQueues.inAttendance)
    }
  }, [isLoadingQueues])

  return (
    <div className={`sidebar-group ${openSidebar || mobileMainQueueSidebar ? "mobile-open" : ""}`}>
      <div className={openSidebar || mainQueueSidebar ? 'sidebar active' : 'sidebar'}>
      <header>
        <span className='d-flex align-items-center' style={{ gap: 10 }} id="teste">Em atendimento {inAttendances.length ? <div className="new-message-count">{inAttendances.length > 90 ? '99+' : inAttendances.length}</div> : ''}</span>
        <a href="/#/" onClick={(e) => closeAction(e)}
          className="btn btn-light">
          <i className="ti ti-close" id="openQueues"></i>
        </a>
      </header>
      <form onSubmit={(e) => e.preventDefault()}>
        <input type="text" className="form-control" placeholder="Procurar conversa" onChange={handleFilter} />
      </form>
      <div className="sidebar-body">
        {!inAttendances.length ?
          <div className="chat">
            <div className="chat-body no-message">
              <div className="no-message-container">
                <i className="fa fa-exclamation-circle"></i>
                <p style={{ textAlign: 'center' }}>No momento não há atendimentos.</p>
              </div>
            </div>
          </div>
          : !filteredResults.length && filterValue ?
            <div className="chat">
              <div className="chat-body no-message">
                <div className="no-message-container">
                  <i className="fa fa-exclamation-circle"></i>
                  <p style={{ textAlign: 'center' }}>No momento não foi encontrado um atendimento com esse filtro.</p>
                </div>
              </div>
            </div>
            :
            <PerfectScrollbar>
              <ul className="list-group list-group-flush">
                {filteredResults.length ?
                  filteredResults.map((chat, i) => <ChatListView chat={chat} key={i} activeChatCustomer={activeChatCustomer} unreadMessages={unreadMessages} chatSelectHandle={chatSelectHandle} />)
                  : inAttendances.map((chat, i) => <ChatListView chat={chat} key={i} activeChatCustomer={activeChatCustomer} unreadMessages={unreadMessages} chatSelectHandle={chatSelectHandle} />)}
              </ul>
            </PerfectScrollbar>
        }
      </div>
      </div>
    </div>
  )
}

export default Index

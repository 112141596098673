import React, { useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AttendanceProvider } from '../Context/AttendanceContext'
import { MessageProvider } from '../Context/MessageContext'
import { mobileProfileAction } from '../Store/Actions/mobileProfileAction'
import { mobileQueuesAction } from '../Store/Actions/mobileQueuesAction'
import { profileAction } from '../Store/Actions/profileAction'
import { queuesAction } from '../Store/Actions/queuesAction'
import DisconnectedModal from "./Modals/DisconnectedModal"
import Chat from "./Partials/Chat/Chat"
import Profile from "./Sidebars/Profile"
import Queues from './Sidebars/Queues'
import { AuthContext, useAuth } from '../Context/AuthContext'
import { useToast } from './Partials/Toast'
import { Button } from 'reactstrap'
import { useHistory } from "react-router-dom"
import Navigation from './../Navigation';
import { mobileMainQueueAction } from '../Store/Actions/mobileMainQueueAction'
import { mainQueueAction } from '../Store/Actions/mainQueueAction'
import ChatsIndex from "./Sidebars/Chats/index"

function Layout() {
  const dispatch = useDispatch();
  const decodedToken = useAuth()
  const { channelList } = useContext(AuthContext)
  const { showWarningToast, dismissAllToasts } = useToast()
  const allChannelsNotConnected = channelList ? channelList.every(channel => channel.status === 'DISCONNECTED') : null
  const history = useHistory()
  const { mainQueueSidebar, mobileMainQueueSidebar, queuesSidebar, mobileQueuesSidebar, profileSidebar, mobileProfileSidebar } = useSelector(state => state);

  const queuesActions = (e) => {
    e.preventDefault();
    dispatch(queuesAction(true));
    dispatch(mobileQueuesAction(true))
    if (profileSidebar && mobileProfileSidebar) {
      dispatch(profileAction(false));
      dispatch(mobileProfileAction(false));
    }
  };

  const mainQueueActions = (e) => {
    e.preventDefault();
    dispatch(mainQueueAction(true));
    dispatch(mobileMainQueueAction(true))
    if (profileSidebar && mobileProfileSidebar) {
      dispatch(profileAction(false));
      dispatch(mobileProfileAction(false));
    }
  };

  useEffect(() => {
    const isMobile = document.body.clientWidth < 768
    if(isMobile){
      dispatch(mainQueueAction(true));
      dispatch(mobileMainQueueAction(true))
    }
    document.querySelector('*').addEventListener('click', (e) => {
      if (document.body.classList.contains('navigation-open') && e.target?.nodeName === 'BODY') {
        document.body.classList.remove('navigation-open')
      }
    });
    if (profileSidebar && mobileProfileSidebar) {
      dispatch(profileAction(false));
      dispatch(mobileProfileAction(false));
    }
  }, []);

  const openChannelsSidebar = (e) => {
    e.preventDefault();
    history.push('/canais')
    dismissAllToasts()
  }

  useEffect(() => {
    if (channelList?.length && allChannelsNotConnected) {
      showWarningToast(
        'Não foi possivel conectar-se ao chat. \n Todos os canais estão desconectados.',
        5000,
        null,
        'chatDisconnected'
      )
      history.push('/canais')
      return
    }

    if (channelList?.length && !allChannelsNotConnected) {
      channelList.map(channel => {
        if (channel.status === 'DISCONNECTED') {
          const tokenHasChannel = decodedToken.channels.find(tokenChannel => tokenChannel.id === channel._id)

          if (!tokenHasChannel) return

          showWarningToast(
            <span>O canal <strong>{channel.name}</strong> está desconectado.</span>,
            30000,
            <Button className="btn btn-light btn-sm" style={{ width: 'fit-content', margin: '10px auto 0 auto' }} onClick={(e) => openChannelsSidebar(e)}>Detalhes</Button>,
            channel.name
          )
        }
      })
    }
  }, [channelList])

  if (channelList && !allChannelsNotConnected) {
    return (
      <AttendanceProvider>
        <div className="layout">
          {!mainQueueSidebar || !mobileMainQueueSidebar ?
            <>
              <a href="/#/" onClick={(e) => mainQueueActions(e)}
                className="btn btn-light btn-toggle right">
                <i className="ti ti-angle-double-right" id="openQueues"></i>
              </a>
            </>
            : null}
          <div className="content">
            {mainQueueSidebar && mobileMainQueueSidebar ?
              <ChatsIndex />
              : null}
            <MessageProvider>
              <Chat />
            </MessageProvider>
            <Profile />
            {queuesSidebar && mobileQueuesSidebar ?
              <Queues />
              : null}
            <DisconnectedModal />
          </div>
          {!queuesSidebar || !mobileQueuesSidebar ?
            <>
              <a href="/#/" onClick={(e) => queuesActions(e)}
                className="btn btn-light btn-toggle left">
                <i className="ti ti-angle-double-left" id="openQueues"></i>
              </a>
            </>
            : null}
        </div>
      </AttendanceProvider>
    )
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', height: '100vh', alignItems: 'center', flexDirection: 'column', flex: 1 }}>
      <h4>
        Carregando status dos canais...
      </h4>
      <i className="fa fa-spinner" style={{ animation: 'spin 3s linear infinite', fontSize: 80, height: 'fit-content' }}></i>
    </div>
  )
}

export default Layout
